import { render, staticRenderFns } from "./ProductTabs.vue?vue&type=template&id=b6581006&"
import script from "./ProductTabs.vue?vue&type=script&lang=ts&"
export * from "./ProductTabs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HTMLContent: require('/var/www/components/HTMLContent.vue').default})
