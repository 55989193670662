



















































































import {
  SfList,
  SfPrice,
  SfQuantitySelector,
  SfRadio,
  SfButton,
  SfAlert,
} from '@storefront-ui/vue';
import {
  computed, defineComponent, PropType, ref, watch,
} from '@nuxtjs/composition-api';
import {
  getBundleProducts,
  getName as getProductName,
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';
import { bundleProductInitialSelector } from '~/modules/catalog/product/helpers/bundleProduct';
import type { WithTypename } from '~/modules/catalog/product/types';
import type { BundleProduct } from '~/modules/GraphQL/types';

import useCart from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'BundleProductSelector',
  components: {
    SfButton,
    SfList,
    SfPrice,
    SfQuantitySelector,
    SfRadio,
    SfAlert,
  },
  props: {
    canAddToCart: {
      type: Boolean,
      required: false,
      default: true,
    },
    product: {
      type: Object as PropType<WithTypename<BundleProduct>>,
      required: true,
    },
  },
  emits: ['update-bundle', 'update-price'],
  setup(props, { emit }) {
    const { loading, addItem, error: cartError } = useCart();
    const bundleProduct = computed(() => getBundleProducts(props.product));
    const selectedOptions = ref({});
    const addToCartError = computed(() => cartError.value?.addItem?.message);

    selectedOptions.value = bundleProductInitialSelector(bundleProduct.value);

    const canChangeQuantity = (bundle) => {
      const selectedOption = bundle.options.find(
        (o) => o.uid === selectedOptions.value[bundle.uid]?.uid,
      );

      return !selectedOption.can_change_quantity;
    };

    const price = computed(() => Object.keys(selectedOptions.value)
      .reduce(
        (s, k) => s
            + Number.parseFloat(selectedOptions?.value[k]?.price as string)
              * selectedOptions.value[k].quantity,
        0,
      ));

    const addToCart = async () => {
      const bundleProductData = computed(() => Object.keys(selectedOptions.value).map((selectedOptionKey) => {
        const selectedOption = selectedOptions.value[selectedOptionKey];
        return {
          uid: selectedOption.uid,
          value: selectedOption.quantity,
        };
      }));

      await addItem({
        product: {
          ...props.product,
          bundle_options: bundleProductData.value,
        } as BundleProduct,
        quantity: 1,
      });
    };

    watch(
      bundleProduct,
      (newVal) => {
        selectedOptions.value = newVal;
      },
      { deep: true },
    );

    watch(
      computed(() => selectedOptions.value),
      () => {
        emit('update-bundle', selectedOptions.value);
        emit('update-price', price.value);
      },
      { deep: true },
    );

    return {
      addToCart,
      bundleProduct,
      canChangeQuantity,
      loading,
      price,
      getProductName,
      getProductPrice,
      selectedOptions,
      addToCartError,
    };
  },
});
