import { render, staticRenderFns } from "./BundleProductOptionSkeleton.vue?vue&type=template&id=efe1f226&"
import script from "./BundleProductOptionSkeleton.vue?vue&type=script&lang=ts&"
export * from "./BundleProductOptionSkeleton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonLoader: require('/var/www/components/SkeletonLoader/index.vue').default})
